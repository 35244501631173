<template>
  <a-modal
    v-model:visible="howToVisible"
    :width="600"
    class="how-to-modal"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :footer="null"
    :zIndex="10000"
  >
    <template #title
      ><div class="title">
        How to enter keyword in username or page name type
      </div></template
    >
    <KeywordHowTo></KeywordHowTo>
  </a-modal>
</template>
<script>
import {
  faXTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import { ref } from 'vue';
import KeywordHowTo from '@/components/KeywordHowTo.vue';
export default {
  name: 'KeywordHowToModal',
  components: { KeywordHowTo },
  setup() {
    const howToVisible = ref(false);
    const closeHowTo = () => {
      howToVisible.value = false;
    };
    return {
      howToVisible,
      closeHowTo,
      faFacebook,
      faYoutube,
      faXTwitter,
      faInstagram,
      faTiktok,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  font-weight: 500;
  color: #272b41;
}
</style>
